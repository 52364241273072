<template>
  <v-app>
    <v-container>
      <v-sheet class="mx-auto my-5" max-width="95%">
        <div class="text-center" v-if="loading === true">
          <v-progress-circular
            :size="70"
            :width="10"
            color="blue"
            indeterminate
          >
          </v-progress-circular>
        </div>
      </v-sheet>
      <v-sheet class="mx-auto my-5" max-width="95%">
        <div class="text-center" v-if="show_error === true">
          {{
            $t(
              "square.決済が正しく行われていません。決済処理はキャンセルされました。クリニックCOMのサポートまでご連絡ください。"
            )
          }}
        </div>
      </v-sheet>

      <v-sheet class="mx-auto my-5" max-width="95%" v-if="loading === false">
        <v-card>
          <v-card-title class="text-center">DGFT決済サービス</v-card-title>

          <v-img
            class="ma-5"
            width="280"
            src="https://www.veritrans.co.jp/assets/img/common/logo_service_veritrans4g.png"
          ></v-img>

          <p class="ma-5" v-if="is_befor == true">
            DGFT決済サービスの準備ができました。<br />
            ご購入を希望される方は、"決済へ進む"を選択してお支払い画面を表示してください。
          </p>

          <p class="ma-5" v-if="is_befor != true">
            ブラウザの戻るボタンなど、ブラウザ操作を行わないでください。
          </p>

          <div
            class="text-center"
            v-if="show_error === false && is_befor == true"
          >
            <input
              type="button"
              id="pay-button"
              class="btn"
              value="決済へ進む"
            />
          </div>
        </v-card>
      </v-sheet>
    </v-container>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
export default {
  layout: "none",
  data() {
    return {
      loading: true,
      card: null,
      show_error: false,
      order: null,
      clinic: null,
      is_befor: false,
    };
  },
  computed: {
    payment_key() {
      return this.$route.params.payment_key;
    },
    order_id() {
      return this.$route.params.order_id;
    },
    pop_client_key() {
      return this.clinic.pop_client_key;
    },
  },

  created: async function () {
    this.loading = true;
    this.is_befor = false;
    await this.fetchOrder();
    await this.fetchClinic();
    await this.add_mounted();
    this.is_befor = true;
  },
  methods: {
    fetchOrder: async function () {
      console.log("this.order_id:", this.order_id);
      const orderRef = await Firebase.firestore()
        .collection("Orders")
        .doc(this.order_id);
      await orderRef.get().then((doc) => {
        if (doc.exists) {
          this.order = doc.data();
          this.order.id = doc.id;
        } else {
          console.log("No Order document!");
        }
        this.error = true;
      });
      await await orderRef
        .update({
          gmoOrderId: this.order.id,
          gmoAmount: this.order.total_payment,
        })
        .then(() => {})
        .catch((error) => {
          Date().toLocaleString("ja") +
            " [error code: 12] orderCollectionInfo: Checkins update error" +
            error;
        });
    },
    fetchClinic: async function () {
      const clinicRef = await Firebase.firestore()
        .collection("Clinics")
        .doc(this.order.clinic_id);
      await clinicRef.get().then((doc) => {
        if (doc.exists) {
          this.clinic = doc.data();
          this.clinic.id = doc.id;
          this.loading = false;
        } else {
          console.log("No Clinic document!");
        }
        this.error = true;
      });
    },
    add_mounted() {
      // 外部スクリプトの読み込み
      const script = document.createElement("script");
      script.src = "https://pay3.veritrans.co.jp/pop/v1/javascripts/pop.js"; // <pop.js URL> を実際のURLに置き換える
      script.setAttribute("data-client-key", this.pop_client_key); // <POP_CLIENT_KEY> を実際のクライアントキーに置き換える
      script.async = true;
      script.onload = () => {
        var options = {};
        var payButton = document.getElementById("pay-button");
        payButton.addEventListener("click", () => {
          // eslint-disable-next-line no-undef
          pop.pay(this.payment_key, options);
          this.is_befor = false;
        });
      };
      document.head.appendChild(script);
    },
  },
};
</script>

<style>
#pay-button {
  background-color: #007bff; /* Change this to your desired background color */
  color: #ffffff; /* Text color */
  border: 2px solid #ffffff; /* White border with 2-point thickness */
  border-radius: 50px; /* Makes the button elliptical */
  margin-bottom: 20px; /* Adjust padding to your liking */
  padding: 10px 20px; /* Adjust padding to your liking */
  font-size: 16px; /* Font size for the text */
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

#pay-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
</style>
